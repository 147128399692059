import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Icon from "../../components/icons/services"
import ApplicationIcon from "../../components/icons/applications"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

export default function Services({ data }) {
  const {
    markdownRemark: { frontmatter },
  } = data

  const {
    markdownRemark: {
      frontmatter: {
        item: { services, sectors, approachs, applications },
      },
    },
  } = data
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <section className="bg-blue-100">
        <div className="lg:relative py-8 sm:py-24 lg:py-24 container">
          <div className="lg:grid lg:grid-cols-2 md:grid">
            <div>
              <h1
                dangerouslySetInnerHTML={{ __html: frontmatter.subtitle }}
              ></h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <p
                className="mt-5 max-w-lg"
                dangerouslySetInnerHTML={{ __html: frontmatter.description }}
              ></p>
            </div>
          </div>
          <div className="py-12 sm:relative sm:py-10 lg:absolute lg:inset-y-0 lg:right-0 lg:w-5/12 lg:max-w-2xl">
            <div className="relative sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full">
              <img
                alt={frontmatter.title}
                className="lg:max-w-sm md:max-w-sm"
                src={`https://storage.googleapis.com/keshif-website/Illustrations/Services.png`}
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="container lg:relative py-8 sm:py-24 lg:py-24">
        <img
          draggable="false"
          className="lg:block md:block hidden absolute right-0 -top-20 pointer-events-none -z-1"
          alt="Section Pattern"
          src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
        />
        <div className="max-w-lg lg:mb-14 md:mb-8 mb-4">
          <h2>Our Key Services</h2>
          <h4 className="lg:mt-10 md:mt-8 mt-4">{services.description}</h4>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5">
          {services.items.map(i => (
            <div
              key={i.name}
              className="flex gap-5 content-start py-7 px-9 bg-gray-50 rounded-sm grid-element hover:shadow-lg cursor-default transition-all"
            >
              <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                <Icon icon={i.name} />
              </div>
              <div className="space-y-2 flex-1">
                <h5 className="font-medium">{i.name}</h5>
                <p>{i.title}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="lg:relative py-8 sm:py-24 lg:py-24 bg-blue-100 z-1">
        <div className="container">
          <div className="grid lg:grid-cols-2 gap-8">
            <div>
              <h2>Sectors</h2>
              <h4 className="lg:mt-7 md:mt-4 mt-4 md:max-w-md lg:max-w-md lg:pr-11 md:pr-11">
                {sectors.description}
              </h4>
            </div>
            <div className="flex gap-3 flex-wrap lg:content-end lg:justify-start">
              {sectors.items.map(i => (
                <span
                  key={i}
                  className="bg-white rounded-md text-purple-600 font-medium px-4 py-3 inline-flex items-center text-sm hover:shadow-lg cursor-default transition-all"
                >
                  {i}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="lg:relative py-8 sm:py-24 lg:py-24 container">
        <img
          draggable="false"
          className="lg:block md:block hidden absolute left-0 -top-20 pointer-events-none -z-1"
          alt="Section Pattern"
          src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
        />
        <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-11">
          <div className="max-w-lg lg:mb-14 md:mb-8 mb-4 z-1">
            <h2>Visual Analytics Applications</h2>
            <h4 className="lg:mt-10 md:mt-8 my-4 lg:mb-14 lg:max-w-md sm:max-w-md">
              {applications.description}
            </h4>
            <img
              alt={applications.title}
              className="shadow-2xl transition-all"
              src="https://storage.googleapis.com/keshif-website/Projects/CitizenRequests-Kosovo/Main.png"
            ></img>
          </div>
          <div className="grid gap-5">
            {applications.items.map(i => (
              <div
                key={i.name}
                className="bg-gray-50 rounded-sm p-7 flex hover:shadow-lg cursor-default transition-all"
              >
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mr-5">
                  <ApplicationIcon icon={i.name} />
                </div>
                <div className="flex-1">
                  <h5 className="font-medium mb-2">{i.name}</h5>
                  <p dangerouslySetInnerHTML={{ __html: i.description }}></p>
                  {i.link && (
                    <Link
                      to={i.link}
                      className="inline-block text-sm text-purple-600 font-medium mt-4"
                    >
                      <FontAwesomeIcon className="mr-4" icon={faArrowRight} />
                      Discover self-service analytics
                    </Link>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="lg:relative py-8 sm:py-24 lg:py-24 bg-gray-100">
        <div className="container">
          <div className="grid">
            <h2>Hosting & Infrastructure</h2>
            <h4 className="mt-4 lg:max-w-xl sm:max-w-xl">
              Our rich data interfaces are supported by backend and hosting
              services that provide reliable, fast, secure access to project
              data and assets.
            </h4>
            <div className="grid lg:grid-cols-4 gap-4 lg:mt-9 mt-4">
              <div className="flex lg:flex-nowrap flex-wrap flex-1 pl-5 gap-2 border-l lg:col-span-2 items-start">
                <p>
                  We are a Google Cloud Partner with expertise in Google Cloud
                  App Dev & Monitoring. We recommend and implement most of our
                  solutions on the Google Cloud, and continuously take care of
                  all application management details.
                </p>
                <img
                  className="w-52 object-contain rounded border border-gray-300"
                  alt="Google Cloud Partner"
                  src="https://storage.googleapis.com/keshif-website/GC-partner.png"
                />
              </div>
              <div className="lg:max-w-xs md:max-w-xs flex-1 pl-5 border-l">
                <p>
                  Our core technology can be deployed on any cloud environment
                  (such as <b>AWS</b> and <b>Azure</b>), and can integrate with
                  data from any database.
                </p>
              </div>
              <div className="lg:max-w-xs md:max-w-xs flex-1 pl-5 border-l">
                <p>
                  We offer <b>licensing</b> for deployment on infrastructure
                  managed by the client, whether on premise or cloud.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:relative py-8 sm:pt-24 lg:py-24">
        <div className="container">
          <div className="grid lg:grid-cols-4 gap-8 items-center">
            <div>
              <h2>Our Approach</h2>
              <h4 className="lg:mt-8 md:mt-8 mt-4">{approachs.description}</h4>
            </div>
            <div className="col-span-3 grid lg:grid-cols-2 md:grid-cols-2 max-w-3xl gap-x-5 lg:gap-y-10 md:gap-y-10 gap-y-5 lg:justify-self-end md:justify-self-end">
              {approachs.items.map(i => (
                <div key={i} className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <p>{i}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        subtitle
        description
        item {
          services {
            description
            items {
              name
              title
            }
          }
          sectors {
            description
            items
          }
          approachs {
            description
            items
          }
          applications {
            title
            description
            items {
              name
              description
              link
            }
          }
        }
      }
    }
  }
`
